<template>
  <div id="NewsDetail">
    <div class="section">
      <div class="container">
        <div v-if="news" class="columns is-multiline">
          <div class="column is-12">
            <p class="title">
              {{ news.header }}
            </p>
            <figure class="image is-3by2">
              <img :src="news.cover_image" />
            </figure>
            <p
              class="pre-formatted"
              style="padding-top: 1.5rem; text-align: left"
            >
              {{ news.description }}
            </p>
          </div>
          <video-embed v-if="news.video" :src="news.video" />
          <div
            v-for="(photo, index) in news.photos"
            :key="index"
            class="column is-4-desktop is-6-tablet is-12-mobile"
          >
            <figure
              class="image hoverable is-16by9"
              @click.prevent="switchToGalleryMode(index)"
            >
              <img class="img-cover" :src="photo" />
            </figure>
          </div>
        </div>
        <div v-else>
          <section class="hero is-large">
            <div class="hero-body">
              <div v-if="noContent" class="container">
                <h2 class="title">
                  <b-icon
                    icon="emoticon-sad-outline"
                    size="is-large"
                    type="is-primary"
                  />
                </h2>
                <h2 class="title">Content Not Found...</h2>
              </div>
              <div v-else class="container">
                <h2 class="title">
                  {{ $t("label.loading") }}
                </h2>
              </div>
            </div>
          </section>
        </div>
      </div>
      <vue-easy-lightbox
        v-if="news"
        :visible="isShowGallery"
        :imgs="news.photos"
        :index="selectedImage"
        @hide="isShowGallery = false"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import NewsApiManager from "@/api/NewsApiManager";
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  name: "NewsDetail",
  components: {
    VueEasyLightbox
  },
  computed: mapState(["current_lang"]),
  data() {
    return {
      news: null,
      noContent: false,
      isShowGallery: false,
      selectedImage: null
    };
  },
  watch: {
    current_lang: function(val) {
      if (val) {
        const news_id = this.$route.params.id;
        this.getNewDetail(news_id);
      }
    }
  },
  methods: {
    navigateToNewsDetail: function(news) {
      this.$router.push({
        name: "news-detail",
        params: { id: news._id }
      });
    },
    switchToGalleryMode: function(index) {
      this.isShowGallery = true;
      this.selectedImage = index;
    },
    getNewDetail: async function(news_id) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await NewsApiManager.requestNewsDetail(news_id);
      switch (response.code) {
        case "0":
          if (response.data) {
            this.noContent = false;
            this.news = response.data;
          } else {
            this.noContent = true;
          }
          break;

        default:
          break;
      }
      loadingComponent.close();
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    const news_id = this.$route.params.id;
    this.getNewDetail(news_id);
  }
};
</script>

<style>
iframe {
  width: 95vw;
  height: 60vh;
}

.img-cover {
  object-position: center;
  object-fit: cover;
}
</style>
